import React from 'react'
import './talent-quote.sass'

const TalentQuote = ({ quote }) => (
  <blockquote className="TalentQuote">
    <p className="TalentQuote__quote">
      {quote.quote}
    </p>
    <cite className="TalentQuote__cite">
      {quote.source}
    </cite>
  </blockquote>
)

export default TalentQuote
