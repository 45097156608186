import React from 'react'
import WorkCarousel from './work-carousel'
import closeIcon from 'images/icon-close-modal.svg'
import './work-modal.sass'

const WorkModal = ({ data, closeModal }) => (
  <div
    className="WorkModal"
    onClick={e => {
      if (e.target.classList.contains('WorkModal__content')) {
        closeModal()
      }
    }}>
    <button
      className="WorkModal__close"
      onClick={closeModal}>
      <img
        src={closeIcon}
        alt="Close icon to close modal" />
    </button>
    <div className="WorkModal__content a-modal-inner">
      <div className="u-wrap">
        <WorkCarousel data={data} />
      </div>
    </div>
  </div>
)

export default WorkModal
