import React from 'react'
import Masonry from 'react-masonry-css'
import WorkBlock from './work-block'
import './work-list.sass'

const breakpointColumnsObj = {
  default: 2,
  767: 1
}

const getWork = (items, children) => {
  const work = [...items]

  if (children) {
    work.length < 3
      ? work.push(children)
      : work.splice(2, 0, children)
  }

  return work.map((item, i) => {
    return (
      <React.Fragment key={i}>
        {(item.props && item.props.quote)
          ? children
          : <WorkBlock
              small
              key={i}
              data={work[i]} />
        }
      </React.Fragment>
    )
  })
}

const WorkList = ({ children, data }) => (
  <section className="WorkList">
    <div className="WorkList__wrap">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="WorkList__grid"
        columnClassName="WorkList__grid-column">
        {getWork(data, children)}
      </Masonry>
    </div>
  </section>
)

export default WorkList
