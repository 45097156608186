import React from 'react'
import './video.sass'

const Video = ({ video, title }) => (
  <div className="Video">
    <div className="Video__wrapper">
      <iframe
        title={title}
        src={`${video}?loop=1&autoplay=1`}
        frameBorder="0"
        allow="autoplay"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen>
      </iframe>
    </div>
  </div>
)

export default Video
