import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo/seo'
import Layout from '../components/layouts/layout'
import TalentHero from '../components/talent/talent-hero'
import TalentQuote from '../components/talent/talent-quote'
import WorkList from '../components/work/work-list'

const Template = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  frontmatter.html = html

  return (
    <Layout>
      <Seo
        title={`${frontmatter.meta.title} - Talent - Maison Hanko`}
        ogImage={frontmatter.thumb.url} />
      <TalentHero
        name_eng={frontmatter.name_eng}
        name_jap={frontmatter.name_jap}
        bio={html}
        job_title={frontmatter.job_title} />
      <WorkList data={frontmatter.work}>
        {frontmatter.blockquote.quote &&
          <TalentQuote quote={frontmatter.blockquote} />
        }
      </WorkList>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(
      frontmatter: {
        url: { eq: $path }
      }
    ) {
      html
      frontmatter {
        meta {
          title
          description
        }
        name_eng
        name_jap
        thumb {
          url
        }
        job_title
        blockquote {
          quote
          source
        }
        work {
          title
          thumb
          media {
            url
            video
          }
        }
      }
    }
  }
`

export default Template
