import React from 'react'
import './talent-hero.sass'

const getName = (name, name_jap) => {
  let n = name.split(' ')
  let n1 = n.shift()
  return `<span class="TalentHero__title__eng">${n1}</span><div class="TalentHero__title__wrap"><span class="TalentHero__title__eng">${n.join(` `)}</span><span class="TalentHero__title__jap">${name_jap ? name_jap : ''}</span></div>`
}

const TalentHero = ({ name_eng, name_jap, bio, job_title}) => (
  <div className="TalentHero">
    <div className="u-wrap">
      <div className="TalentHero__flex">
        {name_eng &&
          <h1
            className="TalentHero__title"
            dangerouslySetInnerHTML={{__html: getName(name_eng, name_jap)}}>
          </h1>
        }
        {bio &&
          <div className="TalentHero__bio">
            <h2>Bio</h2>
            <div className="TalentHero__bio-wrap">
              <div dangerouslySetInnerHTML={{__html: bio}} />
              <p className="TalentHero__bio-job">{job_title}</p>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
)

export default TalentHero
