import React, { Component } from 'react'
import WorkModal from './work-modal'
import { CSSTransition } from 'react-transition-group'
import './work-block.sass'

class WorkBlock extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  handleKeydown = (event) => {
    if (event.keyCode === 27) {
      this.toggleModal(false)
    }
  }

  toggleModal(showModal) {
    if (showModal) {
      document.addEventListener('keydown', this.handleKeydown)
      document.body.classList.add('body--no-scroll')
    } else {
      document.removeEventListener('keydown', this.handleKeydown)
      document.body.classList.remove('body--no-scroll')
    }
    this.setState({ showModal })
  }

  getContent() {
    return (
      <>
        <CSSTransition
          in={this.state.showModal}
          unmountOnExit
          timeout={300}
          classNames="a-modal">
          <WorkModal
            data={this.props.data}
            closeModal={() => this.toggleModal(false)} />
        </CSSTransition>
      </>
    )
  }

  render() {
    return (
      <article className="WorkBlock">
        <button
          className="WorkBlock__button"
          onClick={() => this.toggleModal(true)}>
          {this.props.data.thumb &&
            <img
              src={this.props.data.thumb}
              alt={this.props.data.title}  />
          }
        </button>
        {this.props.small
          ? this.getContent()
          : <div className="u-wrap">{this.getContent()}</div>
        }
      </article>
    )
  }

}

export default WorkBlock
