import React from 'react'

const WorkMeta = ({ date, clientName, tag, prefix }) => {
  const Tag = tag

  return (
  <>
    {(date || clientName) &&
      <Tag>
        {prefix && <> - </>}
        {date}
        {date && clientName &&
          <span> - </span>
        }
        {clientName}
      </Tag>
    }
  </>
  )
}

export default WorkMeta


