import React, { Component } from 'react'
import { Swipeable, defineSwipe } from 'react-touch'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Video from '../video/video'
import WorkMeta from '../work/work-meta'
import arrow from 'images/icon-arrow-right.svg'
import logo from 'images/hanko-logo-icon.svg'
import './work-carousel.sass'


const getTitle = (title, i) => {
  return `${title.replace(' ', '-').toLowerCase()}-video-${i}`
}

class WorkCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imagesLoaded: 0,
      currentSlide: 0,
      direction: 'right',
    }
    this.images = this.props.data.media.filter( item => item.url)
    this.multiSlide = this.props.data.media.length > 1
  }

  goToSlide(currentSlide, direction) {
    if (currentSlide === -1) {
      currentSlide = this.props.data.media.length - 1
    }
    if (currentSlide === this.props.data.media.length) {
      currentSlide = 0
    }
    this.setState({
      currentSlide,
      direction,
    })
  }

  componentDidMount() {
    this.lazyLoadAllImages()
  }

  lazyLoadAllImages() {
    for (let image of this.images) {
      let img = new Image()
      img.onload = () => {
        this.setState({
          imagesLoaded: this.state.imagesLoaded + 1
        })
      }
      img.src = image.url
    }
  }

  handleMouseDown = e => {
    this.startPos = e.pageX
  }

  handleDrag = direction => {
    const slide = direction === 'left'
      ? this.state.currentSlide + 1
      : this.state.currentSlide - 1
    this.goToSlide(slide, direction)
  }

  getSlides() {
    return (
      <CSSTransition
        key={this.state.currentSlide}
        timeout={800}
        classNames={`a-carousel--${this.state.direction}`}>
        {this.props.data.media[this.state.currentSlide].video
          ? this.getVideo()
          : this.getImage()
        }
      </CSSTransition>
    )
  }

  getImage() {
    return (
      <Swipeable
        config={defineSwipe({ swipeDistance: 10 })}
        onSwipeLeft={() => this.handleDrag('left')}
        onSwipeRight={() => this.handleDrag('right')}>
        <div className="WorkCarousel__loader-wrap">
          <img
            className="WorkCarousel__loader"
            src={logo}
            alt="Icon showing two vertical bars acting as a loading indicator" />
          <div
            className={`
              WorkCarousel__slide
              WorkCarousel__slide--image
              ${this.state.imagesLoaded === this.images.length ? 'WorkCarousel__slide--loaded' : ''}
            `}
            style={{
              backgroundImage: `url(${this.props.data.media[this.state.currentSlide].url})`
            }}>
          </div>
        </div>
      </Swipeable>
    )
  }

  getVideo() {
    return (
      <div className="WorkCarousel__slide WorkCarousel__slide--video">
        <Video
          video={this.props.data.media[this.state.currentSlide].video}
          title={getTitle(this.props.data.title, this.state.currentSlide + 1)} />
      </div>
    )
  }

  getButtons() {
    return (
      <>
        <button
          onClick={e => this.goToSlide(this.state.currentSlide - 1, 'right')}
          className="WorkCarousel__arrow WorkCarousel__arrow--left">
          <img
            src={arrow}
            alt="Arrow icon pointing right" />
        </button>
        <button
          onClick={e => this.goToSlide(this.state.currentSlide + 1, 'left')}
          className="WorkCarousel__arrow WorkCarousel__arrow--right">
          <img
            src={arrow}
            alt="Arrow icon pointing left" />
        </button>
      </>
    )
  }

  render() {
    return (
      <div className="WorkCarousel">
        <div className="WorkCarousel__footer">
          {this.multiSlide &&
            <div className="WorkCarousel__counter">
              {this.state.currentSlide + 1}/{this.props.data.media.length}
            </div>
          }
          <h2>
            {this.props.data.title}
            <WorkMeta
              clientName={this.props.data.client_name}
              date={this.props.data.date}
              prefix
              tag="span" />
          </h2>
        </div>
        <div className="WorkCarousel__wrap">
          <div className="WorkCarousel__slides">
            <TransitionGroup>
              {this.getSlides()}
            </TransitionGroup>
          </div>
          {this.multiSlide &&
            this.getButtons()
          }
        </div>
      </div>
    )
  }
}

export default WorkCarousel
